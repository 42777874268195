/* eslint-disable no-unused-vars */
import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import { PATH_AFTER_LOGIN } from '../config';
import LoadingScreen from '../components/LoadingScreen';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    { path: '/', element: <Home /> },
    { path: '/login/admin', element: <Login /> },
    { path: '/codemail', element: <CodeMail /> },
    { path: '/login/company', element: <CompanyLogin /> },
    { path: '/login/clinic', element: <ClinicLogin /> },
    { path: '/forget', element: <Register /> },
    { path: '/reset-passsword', element: <ResetPassword usertype={'company'} /> },
    { path: '/clinic-reset-passsword', element: <ResetPassword usertype={'clinic'} /> },
    {
      path: 'dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'home', element: <GeneralAnalytics /> },
        { path: 'companyProfile', element: <CompanyProfile /> },

        { path: 'user', element: <Spell /> },
        { path: 'adduser', element: <AddSpell /> },
        { path: 'viewuser/:id', element: <ViewSpell /> },
        { path: 'edituser/:id', element: <EditSpell /> },

        { path: 'order', element: <Order /> },
        { path: 'addorder', element: <AddOrder /> },
        { path: 'vieworder/:id', element: <ViewOrder /> },
        { path: 'editorder/:id', element: <EditOrder /> },

        { path: 'eventbook', element: <EventBooked /> },
        { path: 'bookeventview/:id', element: <ViewEventBook/> },



        { path: 'coupon', element: <Coupon /> },
        { path: 'addcoupon', element: <AddCoupon /> },
        { path: 'viewcoupon/:id', element: <ViewCoupon /> },
        { path: 'editcoupon/:id', element: <EditCoupon /> },

        { path: 'event', element: <Event /> },
        { path: 'addevent', element: <AddEvent /> },
        { path: 'editevent/:id', element: <EditEvent /> },
        { path: 'viewevent/:id', element: <ViewEvent /> },

        { path: 'menu', element: <Menu /> },
        { path: 'addmenu', element: <AddMenu /> },
        { path: 'editmenu/:id', element: <EditMenu /> },
        { path: 'viewmenu/:id', element: <ViewMenu /> },

        { path: 'item', element: <Item /> },
        { path: 'additem', element: <AddItem /> },
        { path: 'edititem/:id', element: <EditItem /> },
        { path: 'viewitem/:id', element: <ViewItem /> },

        { path: 'percantage', element: <AddPercantage /> },

        { path: 'change_password', element: <ResetPassword /> },

        {
          path: 'e-commerce',
          children: [{ element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true }],
        },
      ],
    },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const CompanyLogin = Loadable(lazy(() => import('../pages/auth/CompanyLogin')));
const ClinicLogin = Loadable(lazy(() => import('../pages/auth/ClinicLogin')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const CodeMail = Loadable(lazy(() => import('../pages/auth/CodeMail')));

const Home = Loadable(lazy(() => import('../pages/dashboard/Home/Home')));

const EditAdmin = Loadable(lazy(() => import('../pages/dashboard/EditAdmin/EditAdmin')));
const CompanyProfile = Loadable(lazy(() => import('../pages/dashboard/Company/CompanyProfile')));


const Spell = Loadable(lazy(() => import('../pages/dashboard/User/User')));
const AddSpell = Loadable(lazy(() => import('../pages/dashboard/User/AddUser')));
const EditSpell = Loadable(lazy(() => import('../pages/dashboard/User/EditUser')));
const ViewSpell = Loadable(lazy(() => import('../pages/dashboard/User/ViewUser')));

const Order = Loadable(lazy(() => import('../pages/dashboard/Order/Order')));
const AddOrder = Loadable(lazy(() => import('../pages/dashboard/Order/AddOrder')));
const EditOrder = Loadable(lazy(() => import('../pages/dashboard/Order/EditOrder')));
const ViewOrder = Loadable(lazy(() => import('../pages/dashboard/Order/ViewOrder')));

const Coupon = Loadable(lazy(() => import('../pages/dashboard/Coupon/Coupon')));
const AddCoupon = Loadable(lazy(() => import('../pages/dashboard/Coupon/AddCoupon')));
const EditCoupon = Loadable(lazy(() => import('../pages/dashboard/Coupon/EditCoupon')));
const ViewCoupon = Loadable(lazy(() => import('../pages/dashboard/Coupon/ViewCoupon')));

const Event = Loadable(lazy(() => import('../pages/dashboard/Events/Event')));
const AddEvent = Loadable(lazy(() => import('../pages/dashboard/Events/AddEvent')));
const EditEvent = Loadable(lazy(() => import('../pages/dashboard/Events/EditEvent')));
const ViewEvent = Loadable(lazy(() => import('../pages/dashboard/Events/ViewEvent')));

const Menu = Loadable(lazy(() => import('../pages/dashboard/MenuCategory/Menu')));
const AddMenu = Loadable(lazy(() => import('../pages/dashboard/MenuCategory/AddMenu')));
const EditMenu = Loadable(lazy(() => import('../pages/dashboard/MenuCategory/EditMenu')));
const ViewMenu = Loadable(lazy(() => import('../pages/dashboard/MenuCategory/ViewMenu')));


const  EventBooked= Loadable(lazy(() => import('../pages/dashboard/BookEvents/BookedEvent')));
const  ViewEventBook= Loadable(lazy(() => import('../pages/dashboard/BookEvents/ViewEventBook')));



const Item = Loadable(lazy(() => import('../pages/dashboard/Items/Items')));
const AddItem = Loadable(lazy(() => import('../pages/dashboard/Items/AddItem')));
const EditItem = Loadable(lazy(() => import('../pages/dashboard/Items/EditItem')));
const ViewItem = Loadable(lazy(() => import('../pages/dashboard/Items/ViewItem')));

const AddPercantage = Loadable(lazy(() => import('../pages/dashboard/Percantage/AddPercantage')));

const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
